import React, {Component} from 'react';
import styles from "./slider.module.css";
import {Button, Container, Icon} from "semantic-ui-react";
import Carousel from 'nuka-carousel';
import {graphql, StaticQuery} from "gatsby";
import MobileContainer from "../../container/MobileContainer";
import TabletContainer from "../../container/TabletContainer";
import DesktopContainer from "../../container/DesktopContainer";

class SliderComp extends Component {
    render() {
        return (
            <StaticQuery query={carouselQuery}
                         render={
                             data => {
                                 const DesktopImages = data.markdownRemark.frontmatter.images.map(i =>
                                     <img alt="" className={styles.image} src={i.desktopImg.childImageSharp.fixed.src}/>
                                 );
                                 const MobileImages = data.markdownRemark.frontmatter.images.map(i =>
                                     <img alt="" className={styles.image} src={i.mobileImg.childImageSharp.fixed.src}/>
                                 );
                                 return (<>
                                     <MobileContainer className={styles.mobile}>
                                         <Container>
                                             <Carousel
                                                 className={styles.carousel}
                                                 initialSlideHeight={135}
                                                 autoplay={true}
                                                 wrapAround={true}
                                                 transitionMode={"scroll"}
                                                 autoplayInterval={1500}
                                                 speed={1000}
                                                 renderCenterLeftControls={({previousSlide}) => (
                                                     <Button icon onClick={previousSlide} className={styles.button}>
                                                         <Icon name={'chevron left'} size={'big'}/>
                                                     </Button>
                                                 )}
                                                 renderCenterRightControls={({nextSlide}) => (
                                                     <Button icon onClick={nextSlide} className={styles.button}>
                                                         <Icon name={'chevron right'} size={'big'}/>
                                                     </Button>
                                                 )}
                                             >
                                                 {MobileImages}
                                             </Carousel>
                                         </Container>
                                     </MobileContainer>
                                     <TabletContainer className={styles.tablet}>
                                         <Container>
                                             <Carousel
                                                 className={styles.carousel}
                                                 initialSlideHeight={350}
                                                 autoplay={true}
                                                 wrapAround={true}
                                                 transitionMode={"scroll"}
                                                 autoplayInterval={1500}
                                                 speed={1000}
                                                 renderCenterLeftControls={({previousSlide}) => (
                                                     <Button icon onClick={previousSlide} className={styles.button}>
                                                         <Icon name={'chevron left'} size={'big'}/>
                                                     </Button>
                                                 )}
                                                 renderCenterRightControls={({nextSlide}) => (
                                                     <Button icon onClick={nextSlide} className={styles.button}>
                                                         <Icon name={'chevron right'} size={'big'}/>
                                                     </Button>
                                                 )}
                                             >
                                                 {DesktopImages}
                                             </Carousel>
                                         </Container>
                                     </TabletContainer>
                                     <DesktopContainer className={styles.desktop}>
                                         <Container>
                                             <Carousel
                                                 className={styles.carousel}
                                                 initialSlideHeight={576}
                                                 pauseOnHover={true}
                                                 autoplay={true}
                                                 wrapAround={true}
                                                 transitionMode={"scroll"}
                                                 autoplayInterval={1500}
                                                 speed={1000}
                                                 renderCenterLeftControls={({previousSlide}) => (
                                                     <Button icon onClick={previousSlide} className={styles.button}>
                                                         <Icon name={'chevron left'} size={'big'}/>
                                                     </Button>
                                                 )}
                                                 renderCenterRightControls={({nextSlide}) => (
                                                     <Button icon onClick={nextSlide} className={styles.button}>
                                                         <Icon name={'chevron right'} size={'big'}/>
                                                     </Button>
                                                 )}
                                             >
                                                 {DesktopImages}
                                             </Carousel>
                                         </Container>
                                     </DesktopContainer>
                                 </>)
                             }
                         }
            />
        );
    }
}

export default SliderComp;

const carouselQuery = graphql`
    query carouselQuery {
      markdownRemark(frontmatter: {type: {eq: "image-gallery-sponsors"}, path: {eq:"/sponsoren/"}}) {
        frontmatter {
          images {
            desktopImg: image {
              childImageSharp {
                fixed(height: 405) {
                  src
                }
              }
            }
            mobileImg: image {
              childImageSharp {
                fixed(height: 135) {
                  src
                }
              }
            }
          }
        }
      }
    }
`;




