import React from "react";
import '../styles/semantic.min.css';
import {Container, Icon, Message} from "semantic-ui-react";
import LayoutContainer from "../container/LayoutContainer";
import SEO from "../components/seo/Seo";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import PageHeader from "../components/utils/PageHeader";
import styles from "../container/layout.css";
import layout from "../templates/layout.module.css";
import MobileTabletContainer from "../container/MobileTabletContainer";
import DesktopContainer from "../container/DesktopContainer";
import SliderComp from "../components/carousel/SliderComp";

export default () => <LayoutContainer>
    <SEO title={'Sponsoren & Förderer'}/>
    <MobileTabletContainer>
        <MainNavigation/>
        <Container fluid style={{marginBottom: '3em'}}>
            <PageHeader title={'Sponsoren & Förderer'}/>
            <SliderComp/>
            {/*<Sponsors/>*/}
            {/*<Container>*/}
            {/*    <Message info className={styles.message}>*/}
            {/*        <Message.Header>Die Liste unserer Sponsoren & Förderer wird derzeit noch aktualisiert. <Icon*/}
            {/*            name={'hourglass half'} size={'large'}/></Message.Header>*/}
            {/*    </Message>*/}
            {/*</Container>*/}
        </Container>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer>
        <div className={layout.pageWrapper}>
            <MainNavigation/>
            <Container fluid style={{marginBottom: '3em'}}>
                <PageHeader title={'Sponsoren & Förderer'}/>
                <SliderComp/>
                {/*<Sponsors/>*/}
                {/*<Container>*/}
                {/*    <Message info className={styles.message}>*/}
                {/*        <Message.Header>Die Liste unserer Sponsoren & Förderer wird derzeit noch aktualisiert. <Icon*/}
                {/*            name={'hourglass half'} size={'large'}/></Message.Header>*/}
                {/*    </Message>*/}
                {/*</Container>*/}
            </Container>
            <Footer/>
        </div>
    </DesktopContainer>

</LayoutContainer>